<template>
  <div class="d-flex flex-column flex-grow-1 rounded">
    <BaseToolBar title="Contrats" subtitle="Liste des contrats disponibles">
      <modal
        ref="modalContrat"
        title="Ajouter"
        subtitle="Ajouter un contrat pour le collaborateur"
        id="AddContrat"
        @store="storeContrat"
      >
        <base-input
          class="pb-4"
          v-model="newContrat.contrat_libelle"
          inputText="Poste"
          :errors="feedback.contrat_libelle"
        ></base-input>
        <BaseSelect
          class="pb-4"
          inputText="Type"
          v-model.number="newContrat.contrat_type_id"
          :options="data.contratTypes"
          :errors="feedback.contrat_type_id"
        ></BaseSelect>
        <BaseSelect
          class="pb-4"
          inputText="Siret"
          v-model.number="newContrat.contrat_siret_id"
          :options="data.etablissements"
          :errors="feedback.contrat_siret_id"
        ></BaseSelect>
        <base-input-date-picker
          class="pb-4"
          v-model="newContrat.contrat_date_debut"
          inputText="Date de début"
          :errors="feedback.contrat_date_debut"
        ></base-input-date-picker>
        <base-input-date-picker
          v-model="newContrat.contrat_date_fin"
          inputText="Date de fin"
          :errors="feedback.contrat_date_fin"
        ></base-input-date-picker>
      </modal>
    </BaseToolBar>
    <div class="d-flex flex-column w-100">
      <trame-uploader
        id="document_contrat"
        :file_system="'CONTRATS'"
        title="Exportez à partir d'un modèle"
        :data="{
          values: {},
          tablesSize: 9,
          tables: [
            {
              key: 'tableau_scn',
              data: {
                header: [
                  { id: 1, text: 'Formation' },
                  { id: 1, text: 'N° Attestation' },
                  { id: 2, text: 'Délivrance' },
                  { id: 3, text: 'Organisme' },
                ],
                body: [],
              },
            },
          ],
          images: [{ key: 'avatar', path: collaborateur_avatar, width: '3cm', height: '3cm', ratio: true }],
        }"
      ></trame-uploader>

      <div class="w-100 d-flex mb-3 p-3 rounded shadow-sm" v-background-3>
        <base-inputDatePicker
          v-model="anciennete"
          inputText="Ancienneté"
          :errors="feedback.anciennete"
        ></base-inputDatePicker>
        <base-button class="ml-3" text="" icon="save" @click="updateCollaborateur" :loading="loading"></base-button>
      </div>

      <div class="flex-grow-1 d-flex flex-column">
        <div v-for="contrat in contrats" :key="contrat.id">
          <contrats-item :contrat="contrat"></contrats-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";

import ContratsItem from "@/components/collaborateurs/profil/forms/contrat/ContratsItem.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import Modal from "@/components/bases/Modal.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import TrameUploader from "@/components/application/TrameUploader.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  components: {
    ContratsItem,
    BaseToolBar,
    Modal,
    BaseInput,
    BaseSelect,
    BaseInputDatePicker,
    TrameUploader,
    BaseButton,
  },
  data() {
    return {
      newContrat: {
        contrat_collaborateur_id: null,
        contrat_libelle: null,
        contrat_type_id: null,
        contrat_siret_id: null,
        contrat_date_debut: null,
        contrat_date_fin: null,
      },
      feedback: {},
      lists: {},
      statut: "",
      loading: false,
    };
  },
  computed: {
    ...mapFields("collaborateurs", [
      "collaborateur.id",
      "collaborateur.collaborateur_avatar",
      "collaborateur.anciennete",
    ]),
    ...mapMultiRowFields("collaborateurs", ["contrats"]),
    ...mapGetters({
      data: "collaborateurs/data",
    }),
  },
  methods: {
    ...mapActions({
      _storeContrat: "collaborateurs/createContrat",
      _updateCollaborateur: "collaborateurs/updateCollaborateur",
    }),
    storeContrat: function () {
      this.feedback = {};
      this.loading = true;
      this._storeContrat(this.newContrat)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    updateCollaborateur: function () {
      this.feedback = {};
      this.loading = true;
      this._updateCollaborateur()
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.newContrat.contrat_collaborateur_id = this.id;
  },
};
</script>
<style lang="css"></style>
