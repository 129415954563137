<template>
  <container-list-extend title="Contrat">
    <template v-slot:title>
      <div class="p-2 flex-grow-1">
        <h5 class="m-0 mr-3 text-truncate">
          Contrat
          <description-from-list
            v-if="contrat.contrat_type_id && data.contratTypes"
            :value="contrat.contrat_type_id"
            :data="data.contratTypes"
            text="Nouveau contrat"
          ></description-from-list>
        </h5>
        <div class="text-muted">
          <small>
            <div v-if="_contrat && _contrat.contrat_libelle">
              <span class="mr-1">{{ _contrat.contrat_libelle }}</span>
              <span v-if="_contrat.contrat_siret"
                >-
                {{
                  _contrat.contrat_siret.siret_societe.societe_nom_reduit
                }}</span
              >
            </div>
            <div v-if="contrat && contrat.contrat_date_debut">
              <span class="mr-1"
                >Début le : {{ dateSimple(contrat.contrat_date_debut) }}</span
              >
              <span v-if="contrat.contrat_date_fin" class="text-danger"
                >- Fin le : {{ dateSimple(contrat.contrat_date_fin) }}</span
              >
              <span v-if="!contrat.contrat_date_fin" class="text-success"
                >- Durée indéterminée</span
              >
            </div>
          </small>
        </div>
      </div>
    </template>
    <template v-slot:form>
      <ContratForm :contrat="contrat"></ContratForm>
    </template>
  </container-list-extend>
</template>
<script>
import DescriptionFromList from "@/components/bases/DescriptionFromList";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import ContratForm from "@/components/collaborateurs/profil/forms/contrat/ContratForm.vue";
import date from "@/mixins/date.js";
import { mapGetters } from "vuex";

export default {
  name: "ContratsItem",
  components: { ContainerListExtend, ContratForm, DescriptionFromList },
  mixins: [date],
  data() {
    return {};
  },
  props: {
    contrat: {
      Type: Object,
      Requierd: true,
      Default: {}
    }
  },
  computed: {
    ...mapGetters({
      data: "collaborateurs/data"
    }),
    _contrat() {
      let versions = this.contrat.contrat_version;
      let versionsFiltered = versions.filter(
        v => this.$moment(v.date_effet) <= this.$moment()
      );

      if (versionsFiltered.length >= 1) {
        return versionsFiltered[versionsFiltered.length - 1];
      } else {
        return versions[versions.length - 1];
      }
    }
  }
};
</script>
<style lang="css"></style>
